import { PayloadAction } from 'common/lib/Actions/types';
import createReducer from 'common/util/createReducer';

import { SET_SEARCH_VERSION } from 'common/modules/search/actions/semanticSearchActions';

import { SearchVersion } from 'common/modules/search/types';

const initialState: SearchVersion = 'full_text';

export default createReducer(initialState, {
  [SET_SEARCH_VERSION]: (_state: SearchVersion, action: PayloadAction<SearchVersion>) => action.payload,
});
